@font-face {
  font-family: 'FuturaBT-Light';
  src: url('/assets/fonts/FuturaBT-Light.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FuturaBT-Light';
  src: url('/assets/fonts/FuturaBT-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FuturaBT-Medium';
  src: url('/assets/fonts/FuturaBT-Medium.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FuturaBT-Medium';
  src: url('/assets/fonts/FuturaBT-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/Futura.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  src: url('/assets/fonts/Futura.otf') format('opentype'), url(/assets/fonts/Futura.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant';
  src: url('/assets/fonts/Cormorant-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant-Italic';
  src: url('/assets/fonts/Cormorant-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant-MediumItalic';
  src: url('/assets/fonts/Cormorant-MediumItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant-SemiBoldItalic';
  src: url('/assets/fonts/Cormorant-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant-BoldItalic';
  src: url('/assets/fonts/Cormorant-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant-Medium';
  src: url('/assets/fonts/Cormorant-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Cormorant-Light';
  src: url('/assets/fonts/Cormorant-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope-Light';
  src: url('/assets/fonts/Manrope-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope-Medium';
  src: url('/assets/fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope-SemiBold';
  src: url('/assets/fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope-Bold';
  src: url('/assets/fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Chronicle Display, Roman';
  src: url('/assets/fonts/Chronicle-Display-Roman.otf') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Georgia-Regular';
  src: url('/assets/fonts/Georgia.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
