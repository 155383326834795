.ngx-pagination {
  margin-bottom: 0 !important;

  a,
  button {
    color: var(--text-secondary-grey) !important;
  }

  li.pagination-previous,
  li.pagination-next {
    vertical-align: sub;
  }

  .pagination-previous a::before,
  .pagination-previous.disabled::before {
    content: '‹' !important;
  }

  .pagination-next a::after,
  .pagination-next.disabled::after {
    content: '›' !important;
  }

  .pagination-previous a::after,
  .pagination-next a::after {
    color: var(--color-black-2) !important;
  }

  .pagination-previous.disabled a::after,
  .pagination-next.disabled a::after {
    color: var(--text-secondary-grey) !important;
  }

  .pagination-previous a::before,
  .pagination-previous.disabled::before,
  .pagination-next a::after,
  .pagination-next.disabled::after {
    font-size: 38px !important;
    margin: 0 14px !important;
    vertical-align: bottom;
  }

  a:hover,
  button:hover {
    background-color: transparent !important;
  }

  .pagination-previous a::before,
  .pagination-previous.disabled::before,
  .pagination-next a::after,
  .pagination-next.disabled::after,
  .pagination-previous.disabled,
  .pagination-next.disabled,
  > li.current,
  li,
  a,
  button {
    padding: 0 !important;
  }

  li a span,
  li span {
    margin: 0 15px !important;
    line-height: 27px;
  }

  li {
    border: none;
    border-bottom: 1px solid transparent;
    margin: 0;
  }

  > li.current {
    color: var(--color-black-2);
    font-weight: bold;
  }
}
