:root {
  --footer-main-color: #f2f2f2;
  --footer-text-color: #767676;
  --sub-footer-copyright-color: #a8a8a8;
  --main-color-black: #000;
  --color-black-2: #222;
  --main-color-dark: #191919;
  --main-color-gray: #e5e5e5;
  --main-color-gray-2: #f5f5f5;
  --main-color-gray-3: #afafaf;
  --main-color-gray-4: #f6f6f6;
  --main-color-gray-5: #ede9e1;
  --color-gray-6: #0000001a;
  --color-gray-7: #d1d1d1;
  --color-gray-8: #cecece;
  --footer-color-gray: #efefef;
  --main-color-light: #eee;
  --main-color-white: #fff;
  --steps-gray: #d8d8d8;
  --text-input-grey: #707070;
  --text-secondary-grey: #777;
  --form-error: #dc3545;
  --form-warning: #bfb000;
  --form-success: #28a745;
  --sale: #8d2026;
  --offer-history-chat-sender-baloon-background: #f0f0f0;
  --offer-history-chat-receiver-baloon-border: #bcbcbc;
  --Nude: #e3bc9a;
  --Metallic: #aaa9ad;
  --Burgundy: #800020;
  --Animal-print: #f48700;
  --Brown: #a52a2a;
  --Black: #000000;
  --Blue: #0000ff;
  --Beige: #f5f5dc;
  --Multicolor: #cccc66;
  --Red: #e53935;
  --Grey: #808080;
  --Pink: #ffc0cb;
  --Silver: #c0c0c0;
  --Green: #008000;
  --Gold: #ffd700;
  --Yellow: #ffff00;
  --Navy: #000080;
  --Orange: #ffa500;
  --Purple: #800080;
}
