input[type='tel'],
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input.mat-datepicker-input {
  &.primary {
    color: var(--main-color-black);
    border-color: var(--main-color-black);
    background: transparent;
    border-width: 0 0 1px 0;
    width: 100%;
    height: 32px;
    transition: all 0.2s ease-in-out;
    &:focus {
      border-width: 0 0 1px 0;
      outline: unset;
    }
  }

  &.secondary {
    border: 1px solid var(--main-color-black);
    width: 100%;
    height: 40px;

    &:focus {
      outline: unset;
    }
  }
  &[readonly] {
    background: #eee;
  }
}

textarea {
  width: 100%;
  resize: unset;
  height: 120px;

  &:focus {
    outline: unset;
  }
}

form.tvb-form {
  .form-group {
    margin-bottom: 15px;

    .form-control {
      background-color: #fff;
      border-color: #e5e5e5;
      -webkit-box-shadow: none;
      -webkit-appearance: none;
      box-shadow: none;
      border-radius: 0px;
      background-color: transparent;
      font-size: 16px;
      height: 35px;
    }

    .form-control:focus {
      border-color: #e5e5e5;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .error_message {
      margin-top: 8px;
      font-size: 12px;
      text-align: left;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px !important;
  }
}
